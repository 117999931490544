<template>
  <v-container v-if="info != undefined && axie1.data">
    <v-row>
      <v-col class="pa-0">
        <v-slide-group
          class="pa-0"
          mandatory
          show-arrows
        >
          <v-slide-item
            v-for="ax in axie1.data.axies.results"
            :key="ax.id"
          >
            <v-card
              class="ma-1"
              color="#2b2e35"
              height="165"
              width="100"
            >
              <v-row v-if="axie1.data" justify="center" align="center" class="text-center">
                <v-col md="12" class="d-flex align-center align-content-center flex-column">
                  <v-img v-if="ax.image" width="128" :src="ax.image" lazy-src="https://storage.googleapis.com/axie-cdn/avatars/egg/plant-plant-egg-full-transparent.png" />
                  <v-img v-else width="128" src="https://storage.googleapis.com/axie-cdn/avatars/egg/plant-plant-egg-full-transparent.png" lazy-src="https://storage.googleapis.com/axie-cdn/avatars/egg/plant-plant-egg-full-transparent.png" />
                  <p class="pa-5">{{ ax.name }}</p>
                </v-col>
              </v-row>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'AxiosCard',
    props: {
      info: String,
      default: () => '',
    },
    data () {
      return {
        axie1: {},
        loaded: false,
      }
    },
    async mounted () {
      this.getAxies()
    },
    methods: {
      async getAxies () {
        var addr = this.info
        var ret = (await this.$http.get('https://axieinfinity.com/graphql-server-v2/graphql?operationName=GetAxieBriefList&query=query%20GetAxieBriefList%20{%20axies(auctionType:%20All,%20from:%200,%20sort:%20PriceAsc,%20size:%20100,%20owner:%20%22' + addr + '%22%20)%20{%20total%20results%20{%20...AxieBrief%20}%20}%20}%20fragment%20AxieBrief%20on%20Axie%20{%20id%20name%20stage%20class%20breedCount%20image%20title%20genes%20battleInfo%20{%20banned%20}%20auction%20{%20currentPrice%20}%20stats%20{%20...AxieStats%20}%20parts%20{%20id%20name%20class%20type%20specialGenes%20}%20}%20fragment%20AxieStats%20on%20AxieStats%20{%20hp%20speed%20skill%20morale%20}')).data
        this.axie1 = ret

        this.loaded = true
      },
    },
  }
</script>

<style>
  .v-slide-group__prev, .v-slide-group__next {
    min-width: 5px;
  }
    /* .v-slide-group__prev {
      min-width: 5px;
      position: absolute;
      top: -40px;
      left: 407px;
    }

    .v-slide-group__next {
      min-width: 5px;
        position: absolute;
        top: 40px;
        left: 350px;
    } */

</style>
