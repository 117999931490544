<template>
  <v-container>
    <v-row v-if="!connected && !params.addr" class="pa-md-10 py-10">
      Please connect..
    </v-row>
    <v-row v-else class="pa-md-10 py-10" style="padding-top: 120px !important; ">
      <v-col md="3">
        <v-card flat class="pa-8 pt-5" elevation="6">
          <v-row no-gutters>
            <v-col align="center">
              <v-btn
                v-if="hover && unlocked && (!params.addr || address === params.addr)"
                style="position: absolute; top: 60px; right: 100px; z-index: 1; border: 1px solid #fff;"
                fab
                @click="openAvatarPicker"
              >
                <v-icon size="20" class="d-flex justify-center">mdi-pen</v-icon>
              </v-btn>
              <v-avatar size="170" style="border: 2px solid #fff; top: -80px; position: relative;">
                <v-img :src="mainHost + `/avatar?addr=` + params.addr" style="position: absolute;" />
              </v-avatar>
              <v-col align="center" class="d-flex flex-column" style="top: -45px; position: relative;">
                <span class="text-h6 has-text-weight-bold">{{ pinfo.display }}</span>
                <span>BRASIL</span>
              </v-col>
            </v-col>
          </v-row>
          <v-card-text class="pa-0">
            <v-row>
              <v-col>
                250/300 XP
                <v-progress-linear
                  value="80"
                  color="orange"
                  height="15"
                  rounded
                >
                  <strong>LEVEL 50</strong>
                </v-progress-linear>
              </v-col>
            </v-row>

            <v-row>
              <v-col v-for="i in 5" :key="i">
                <v-icon large>mdi-account-circle</v-icon>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                align="start"
                class="d-flex flex-column pb-5"
              >
                <span>Name: <strong>Andre Balada</strong></span>
                <span>Language: <strong>English</strong></span>
                <span>Age: <strong>21</strong></span>
                <span>Team: <strong>Viking Age</strong></span>
              </v-col>
            </v-row>

            <v-divider />

            <v-row no-gutters>
              <v-col class="py-5" align="start">
                <v-tooltip v-for="item, i in pinfo.profiles" :key="i" bottom>
                  <template #activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs"
                      class="ma-1"
                      v-on="on"
                    >
                      <span v-if="i === 'axie'">{{ item.name }}</span>
                      <span v-else-if="i === 'lol'">{{ item.name }}#{{ item.region }} </span>
                      <span v-else-if="i === 'discord'">{{ item.username }}#{{ item.discriminator }}</span>
                      <span v-else-if="i === 'twitch'">{{ item.login }}</span>
                    </v-chip>
                  </template>
                  <span>{{ i.charAt(0).toUpperCase() + i.slice(1) }}</span>
                </v-tooltip>

              </v-col>
            </v-row>

            <v-divider />

            <v-row class="pt-5">
              <v-btn @click="hookTwitch">Hook Twitch</v-btn><br>
              <v-col v-for="item, i in pinfo.profiles" :key="i">
                <v-btn v-if="i === 'twitch'" icon :href="`https://twitch.tv/${ item.login }`" target="_blank"><v-icon large>mdi-twitch</v-icon></v-btn>
              </v-col>
            </v-row>

          </v-card-text>

        </v-card>
      </v-col>
      <v-col md="8" class="py-md-0" style="top: -42px; position: relative;">
        <p class="text-h4 ">My Axies</p>
        <v-row>
          <v-col md="6">
            <v-card class="fill-height pa-5" elevation="6">
              <span v-if="pinfo.profiles && pinfo.profiles.axie">
                <axios-card v-if="clicked" :info="pinfo.profiles.axie.ronin" />
                <v-btn v-if="clicked === false" small @click="showAxies">Load Axies</v-btn>
              </span>
            </v-card>
          </v-col>
          <v-col md="6">
            <v-card class="fill-height pa-5" elevation="6">
              My Wins
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <v-card class="fill-height pa-5" elevation="6">
              My Graph
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <v-card class="fill-height pa-5" elevation="6">
              My Matches<br>

            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="showAvatarPicker" :fullscreen="$vuetify.breakpoint.xs" width="1000">
      <v-container>
        <v-card class="fill-height">
          <v-toolbar dark color="">
            <v-btn icon dark @click="showAvatarPicker = false">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-toolbar>
          <v-sheet class="pa-10">
            <v-row>
              <v-col>
                <p class="text-h5 mb-0">Change Your Display Name</p>
                <v-text-field
                  ref="display"
                  v-model="display"
                  class="pt-2"
                  label="Display name"
                  hint="At least 3 characters"
                  name="display"
                  outlined
                  maxlength="25"
                  counter
                />
                <v-btn class="ma-0" text outlined @click="setDisplay">Set Nickname</v-btn>
              </v-col>

            </v-row>
            <v-row>
              <v-col>
                <p class="text-h4 mb-0">Upload Profile Picture</p>
                <p class="text-caption">Customize your profile by adding a profile picture.</p>

                <p class="text-h5 font-weight-black py-2 ma-0">Profile Picture</p>

                <v-avatar size="150" color="#20232a">
                  <v-img :src="url" />
                </v-avatar>
                <p class="pt-2 text-caption">We recommend an image that is 256 x 256 pixels.</p>
                <v-file-input
                  v-model="image"
                  :rules="rules"
                  style="top: 0;right: 0;bottom: 20px;"
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="Pick an avatar"
                  prepend-icon="mdi-camera"
                  label="Avatar"
                />
                <v-btn text outlined @click="submitFiles">Save</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-divider />
              <v-col cols="12">
                <p class="text-h4 mb-0">Conexões recomendadas</p>
                <p class="text-caption">Gerencie seus serviços e contas conectadas.</p>
              </v-col>
              <v-col class="d-flex pa-0 flex-column">
                <v-card class="pa-5 mb-5" elevation="6">
                  <p>Discord</p>
                  <p>Lorem vc aceita a gg blablabla, desvincule</p>
                  <v-btn @click="connectDiscord">LINK DISCORD <v-icon right>mdi-discord</v-icon></v-btn><br>
                </v-card>
                <v-card class="pa-5 mb-5" elevation="6">
                  <p>Twitch</p>
                  <p>Lorem vc aceita a gg blablabla, desvincule</p>
                  <v-btn @click="connectTwitch">LINK TWITCH <v-icon right>mdi-twitch</v-icon></v-btn>
                </v-card>
                <v-card class="pa-5 mb-5" elevation="6">
                  <p>Axies</p>
                  <p>Lorem vc aceita a gg blablabla, desvincule</p>
                  <v-btn @click="connectAxies">LINK AXIES</v-btn>
                </v-card>
                <v-card class="pa-5 mb-5" elevation="6">
                  <p>Riot Games</p>
                  <p>Lorem vc aceita a gg blablabla, desvincule</p>
                  <v-text-field
                    ref="region"
                    v-model="lolRegion"
                    class="py-2"
                    hide-details
                    label="Region"
                    name="region"
                    dense
                    outlined
                    single-line
                  />
                  <v-text-field
                    ref="username"
                    v-model="lolUsername"
                    class="py-2"
                    hide-details
                    label="Username"
                    name="username"
                    dense
                    outlined
                    single-line
                  />
                  <v-btn @click="connectLeague">CONNECT LOL</v-btn>
                </v-card>
              </v-col>
            </v-row>
          </v-sheet>
        </v-card>
      </v-container>
    </v-dialog>
  </v-container>
</template>

<script>
  import {
    mapActions,
    mapState,
  } from 'vuex'
  import AxiosCard from '../../components/profile/AxiosCard.vue'

  export default {
    name: 'Streams',
    components: { AxiosCard },
    data () {
      return {
        clicked: false,
        show: false,
        lolUsername: '',
        lolRegion: '',
        display: '',
        pinfo: {},
        axie: {},
        social: {},
        x: {},
        image: null,
        showAvatarPicker: false,
        rules: [
          value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
        ],
      }
    },
    computed: {
      ...mapState('wallet', ['address', 'unlocked']),
      ...mapState('route', ['params']),
      ...mapState('profiles', ['profiles']),
      ...mapState('platform', ['mainHost']),
      url () {
        if (!this.image) return
        return URL.createObjectURL(this.image)
      },
    },
    async mounted () {
      this.getGames()
    },
    methods: {
      ...mapActions('profiles', ['getProfile']),
      ...mapActions('wallet', ['sendMsg', 'connected']),
      ...mapActions('node', ['setNode', 'grabStreams']),
      submitFiles () {
        if (this.image) {
          console.log('img', this.image)
          const formData = new FormData()
          // files
          var file = this.image
          formData.append('files', file, file.name)

          // additional data
          formData.append('addr', this.address)

          this.$http
            .post(this.mainHost + '/upload_avatar', formData)
            .then(response => {
              console.log('Success!')
              console.log({ response })
            })
            .catch(error => {
              console.log({ error })
            })
        } else {
          console.log('there are no files.')
        }
      },
      async getGames () {
        var addr = this.params.addr || this.address
        if (!this.profiles[addr]) await this.getProfile(addr)
        this.pinfo = this.profiles[addr]
        this.social = this.pinfo.profiles

        // get profile info..
        // var ret = (await this.$http.get(this.mainHost + '/profile?addr=' + this.params.addr)).data
        // this.pinfo = ret
      },
      async getAxie () {
        console.log(this.pinfo)
        var addr = this.pinfo.profiles.axie.ronin
        var ret = (await this.$http.get('https://axieinfinity.com/graphql-server-v2/graphql?operationName=GetAxieBriefList&query=query%20GetAxieBriefList%20{%20axies(auctionType:%20All,%20from:%200,%20sort:%20PriceAsc,%20size:%20100,%20owner:%20%22' + addr + '%22%20)%20{%20total%20results%20{%20...AxieBrief%20}%20}%20}%20fragment%20AxieBrief%20on%20Axie%20{%20id%20name%20stage%20class%20breedCount%20image%20title%20genes%20battleInfo%20{%20banned%20}%20auction%20{%20currentPrice%20}%20stats%20{%20...AxieStats%20}%20parts%20{%20id%20name%20class%20type%20specialGenes%20}%20}%20fragment%20AxieStats%20on%20AxieStats%20{%20hp%20speed%20skill%20morale%20}')).data
        this.axie = ret

        this.clicked = true
      },
      async connectLeague () {
        // send req to server to pull wallet axie info..
        if (this.lolUsername.length < 2) {
          console.log('cant send empty')
          return
        }

        var name = this.lolUsername
        var region = this.lolRegion // 'br1'
        this.sendMsg(JSON.stringify({ msg: 'add_profile', game: 'lol', name, region }))
      },
      async setDisplay () {
        var display = this.display
        if (this.display.length > 25) {
          console.log('to long')
          return
        }

        if (this.display.length < 3) {
          console.log('to short')
          return
        }

        this.sendMsg(JSON.stringify({ msg: 'set_display', display }))

        this.pinfo.display = display
      },
      async connectAxies () {
        // send req to server to pull wallet axie info..
        this.sendMsg(JSON.stringify({ msg: 'add_profile', game: 'axie' }))
      },
      showAxies () {
        this.show = !this.show
        this.clicked = !this.clicked
      },
      connectDiscord () {
        // window.open('https://discord.com/api/oauth2/authorize?response_type=code&client_id=862095461212880906&scope=identify&redirect_uri=https%3A%2F%2Fgoodgame.video/discord.php', '_blank').focus()
        window.open('https://discord.com/api/oauth2/authorize?response_type=code&client_id=862095461212880906&scope=identify&redirect_uri=https://goodgame.video/connect_api?state=discord', '_blank').focus()
      },
      connectTwitch () {
        window.open('https://id.twitch.tv/oauth2/authorize?client_id=iusl7wgf9rnui8an9fnggq2vic5355&redirect_uri=https://goodgame.video/connect_api?state=twitch&response_type=code', '_blank').focus()
      },
      openAvatarPicker () {
        if (this.unlocked && (!this.params.addr || this.address === this.params.addr)) {
          this.showAvatarPicker = true
        }
      },
      async hookTwitch () {
        var r = (await this.$http.get(this.mainHost + '/twitchHook?addr=' + this.params.addr)).data
        console.log('request twitch hook', r)
      },
    },
  }
</script>

<style>
  .v-dialog::-webkit-scrollbar {
    overflow-y: hidden !important;
  }
</style>
